const customVideoElement = document?.getElementById('vimeo-custom-video');
const customTabElement = document?.getElementById('vimeo-custom-tab');
const closeVideoElement = document?.getElementById('close-vimeo-modal');
const body = document?.querySelector('body');
const iframe = document.getElementById('vimeo-iframe');
const player = new Vimeo.Player(iframe);
// Initialize the Vimeo Player

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap?.registerPlugin(ScrollTrigger);

const toggleOpenCloseVideo = () => {
	body?.classList?.toggle('overflow');
	customVideoElement?.classList?.toggle('active');
	player?.getPaused()?.then(() => {
		if(customVideoElement?.classList?.contains('active')) player?.play();
		else player?.pause();
	});
}

customTabElement?.addEventListener('click', toggleOpenCloseVideo);
closeVideoElement?.addEventListener('click', toggleOpenCloseVideo);

gsap?.to("#vimeo-custom-tab", {
	scrollTrigger: {
		trigger: "#vimeo-custom-tab",
		start: "top 40%",
		end: "bottom top",
		scrub: true,
	},
	opacity: 0,
});
